import styled from 'styled-components'

interface LinkProps {
  primary?: boolean
}

export const Link = styled.a<LinkProps>`
  position: relative;
  font-family: 'Source Sans', sans-serif;
  color: ${(props) => (props.primary ? '#e01a22' : '#6e6e6e;')};
  display: inline-block;
  text-decoration: underline;
  outline: none;
  cursor: pointer;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: -5px;
    bottom: -2px;
    right: -5px;
    border-radius: 2px;
    transition: box-shadow 0.2s;
  }

  &:hover,
  &:focus {
    &::before {
      box-shadow: 0 0 0 2px
        ${(props) => (props.primary ? '#e01a22' : '#6e6e6e;')};
    }
  }
`
