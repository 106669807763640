import { HTMLProps } from 'react'
import styled from 'styled-components'
import { Link } from '../../text/Link'
import { GenericModal, GenericModalProps } from './GenericModal'

const PrivacyModelContentDiv = styled.div`
  font-family: 'Source Sans', sans-serif;
`

const PrivacyPolicyOrderedListObject = styled.li`
  font-size: 24px;
  font-weight: bold;
`

const PrivacyPolicyParagraph = styled.p`
  font-weight: normal;
  font-size: 16px;
`

const PrivacyPolicyOrderedList = styled.ol`
  margin: 0;
`

const PrivacyPolicyUnorderedList = styled.ul`
  margin: 0;
`

function ModalLink(props: HTMLProps<HTMLAnchorElement>) {
  return (
    <Link primary={true} target='_blank' href={props.href}>
      {props.children}
    </Link>
  )
}

const PrivacyPolicyBulletPointListObject = styled.li`
  font-weight: normal;
  font-size: 16px;
  list-style-type: disc;
`

const SubHeader = styled.span`
  font-weight: bold;
  font-size: 18px;
`

const ContactDetailsParagraph = styled.p`
  font-weight: normal;
  font-size: 16px;
`
function OverviewContent() {
  return (
    <PrivacyPolicyOrderedListObject>
      <span>Overview</span>

      <PrivacyPolicyParagraph>
        At Coles, we respect the privacy of your personal information in our
        care. Personal information means information that identifies you as an
        individual or from which you can be reasonably identified.
      </PrivacyPolicyParagraph>

      <PrivacyPolicyParagraph>
        This Privacy Policy applies to all Coles Group businesses including
        Supermarkets (Coles Supermarkets, Coles Supply Chain, Coles Gift Cards,
        Coles Online, Coles Financial Services, Coles Local), Coles Liquor
        (Liquorland, First Choice, Vintage Cellars and First Choice Liquor
        Market), and Coles Convenience (Coles Express). When you see “Coles” in
        this policy, it refers to each of these Coles Group businesses.
      </PrivacyPolicyParagraph>

      <PrivacyPolicyParagraph>
        This Privacy Policy provides you with information about the personal
        information we collect and handle about you as our customers, members of
        the public, visitors to our websites, and users of Coles apps, social
        media, and other digital services.
      </PrivacyPolicyParagraph>

      <PrivacyPolicyParagraph>
        In addition to this Privacy Policy, many Coles Group businesses and
        their third-party partners (where applicable) have privacy notices and
        other terms that provide further information about your privacy, e.g.
        Coles Insurance, Coles Pet Insurance, Coles MasterCard, and Coles
        Personal Loans. Those additional privacy notices and terms contain
        important information about the collection, use and disclosure of
        personal information by Coles Group and the third-party partners.
      </PrivacyPolicyParagraph>

      <PrivacyPolicyParagraph>
        Coles is a participating partner in the flybuys program (“Loyalty
        Pacific Pty Ltd”). You can find the flybuys privacy policy at
        <ModalLink href='http://www.flybuys.com.au/about/#/privacy-policy'>
          http://www.flybuys.com.au/about/#/privacy-policy.
        </ModalLink>
      </PrivacyPolicyParagraph>

      <PrivacyPolicyParagraph>
        Our Coles team members can contact their Line Manager, mycoles or myhub
        for details about the personal information handled as part of their
        employment. Job applicants should refer to the Privacy Policy located on
        the Coles career page at
        <ModalLink href='www.colescareers.com.au/privacy'>
          www.colescareers.com.au/privacy.
        </ModalLink>
      </PrivacyPolicyParagraph>

      <PrivacyPolicyParagraph>
        For information about privacy generally, you may contact the Office of
        the Australian Information Commissioner whose details are set in Section
        9.
      </PrivacyPolicyParagraph>

      <PrivacyPolicyParagraph>
        We reserve the right to change the Privacy Policy at any time, so please
        check back regularly to keep informed of updates to this Policy.
      </PrivacyPolicyParagraph>
    </PrivacyPolicyOrderedListObject>
  )
}

function WhyDoWeCollectAndHandleYourPersonalInformationContent() {
  return (
    <PrivacyPolicyOrderedListObject>
      <span>Why do we collect and handle your personal information?</span>

      <PrivacyPolicyParagraph>
        We collect, hold, use and disclose your personal information so that we
        can provide goods and services to you, improve and personalise our
        services for you and operate our business effectively. This includes,
        but is not limited to:
      </PrivacyPolicyParagraph>

      <ul>
        <PrivacyPolicyBulletPointListObject>
          Managing your requests for products and services, including
          deliveries, processing payments, providing refunds and discounts;
          Responding to feedback or concerns you have regarding our products and
          services;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Registering and servicing your account, including keeping your
          information up-to-date, and verifying your identity;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Communicating with you about our products, services, and promotions
          (including direct marketing);
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Requesting feedback through surveys and research so that we can
          improve our products and services;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Improving our operational processes to enhance your customer
          experience;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Working with our service providers;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Safety, security, investigative, fraud, and loss prevention
          activities;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Facilitating corporate transactions like mergers and acquisitions,
          e.g. to assess those transactions and manage the transition of the
          business;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Complying with our legal obligations and protecting and defending our
          legal rights and interests;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Interacting with Regulators and relevant government entities; and,
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          As otherwise required or permitted by law.
        </PrivacyPolicyBulletPointListObject>
      </ul>

      <PrivacyPolicyParagraph>
        Where you provide us with personal information about someone else, you
        must have their consent to provide their personal information to us
        based on this Privacy Policy.
      </PrivacyPolicyParagraph>
    </PrivacyPolicyOrderedListObject>
  )
}

function TypesOfPersonalInformationCollectedContent() {
  return (
    <PrivacyPolicyOrderedListObject>
      <span>Types of personal information collected</span>

      <PrivacyPolicyParagraph>
        The types of personal information we collect for the purposes listed in
        Section 2 includes, but is not limited to:
      </PrivacyPolicyParagraph>
      <ul>
        <PrivacyPolicyBulletPointListObject>
          <strong>Your identity and contact details:</strong> this includes your
          name, residential address, email address, telephone number(s), age and
          gender, government ID (e.g. driver’s license);
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          <strong>Basic household information:</strong> this includes number and
          ages of people living in a household;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          <strong>Financial and transaction information:</strong> this includes
          payment cards and transaction history;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          <strong>Health information:</strong> this includes medical or hospital
          services in connection with an injury/condition related to a public
          liability or workers compensation claim that has been raised with
          Coles;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          <strong>Loyalty program information:</strong> information about your
          participation in Coles loyalty clubs and programs (e.g. Vintage
          Cellars Wine Club), Coles employee discount cards, and the flybuys
          loyalty program;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          <strong>Location information:</strong> our websites or apps might ask
          for location information to help better serve you information and, if
          you have given permission to our app, device data to help serve
          relevant information about nearby stores or information applicable to
          your region;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          <strong>User data:</strong> information about you as a customer of our
          stores and how you engage with our products and services. For example,
          transactional data, product purchases, interests, feedback you provide
          on your shopping experience and audio and video footage captured
          in-store and within facilities;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          <strong>Usage and Interaction data:</strong> details of how you
          interact with our products and services including what you click on
          and interact with across our Coles Digital Services (see Section 6 for
          further information);
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          <strong>Contact history:</strong> details of your contact and
          interactions with our Customer Care teams across Coles; and,
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          <strong>Marketing and communications data:</strong>
          this includes your marketing preferences, channel preferences, and
          your interaction with Coles Digital Services and marketing.
        </PrivacyPolicyBulletPointListObject>
      </ul>
      <SubHeader>Anonymity</SubHeader>
      <PrivacyPolicyParagraph>
        You have the option to engage with Coles anonymously. However, if you
        choose to interact with Coles anonymously, we may not be able to provide
        our products or services to you, or make some offers available to you
        without your personal information. For example, we may not be able to
        deliver items ordered online to your home address if you do not provide
        us with your residential address, or we may not be able to award you
        flybuys points for transactions made at store if you do not provide your
        flybuys number.
      </PrivacyPolicyParagraph>
    </PrivacyPolicyOrderedListObject>
  )
}

function HowPersonalInformationIsCollectedContent() {
  return (
    <PrivacyPolicyOrderedListObject>
      <span>How personal information is collected and held</span>
      <PrivacyPolicyParagraph>
        We collect your personal information when you interact or transact with
        us. This includes, but is not limited to, when you:
      </PrivacyPolicyParagraph>

      <ul>
        <PrivacyPolicyBulletPointListObject>
          Visit a store, make a purchase in store, or place an order online;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Use your flybuys card and payment cards (i.e. credit card or debit
          card);
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Registering for a service where we collect personal information;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Participate in flybuys and/or our other loyalty programs;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Participate in a promotion, competition, or survey;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Request customer service or contact us (including by email and
          telephone);
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Post a review or comment on one of our websites or social media pages,
          or post a rating, a review, or other user generated content on our
          websites or apps; or,
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Use our related websites, apps, social media, and other digital
          services (see Section 5 – Coles Digital Services for further
          information).
        </PrivacyPolicyBulletPointListObject>
      </ul>

      <PrivacyPolicyParagraph>
        We may also collect personal information from third parties including
        from:
      </PrivacyPolicyParagraph>

      <ul>
        <PrivacyPolicyBulletPointListObject>
          Public sources (for example, in public registers or social media);
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Information service providers (for example, if you apply for credit,
          we may ask a credit reporting body for your credit report);
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Providers who administer Coles-branded products and services (for
          example, our financial service partners for payment cards and
          insurance); and,
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Anyone authorised to act on your behalf.
        </PrivacyPolicyBulletPointListObject>
      </ul>

      <PrivacyPolicyParagraph>
        We may also generate new personal information from time to time – e.g.
        reports or analysis based on other information we hold about you.
      </PrivacyPolicyParagraph>
      <PrivacyPolicyParagraph>
        We hold personal information electronically and in hard copy, at our own
        facilities and with the assistance of our service providers. We
        implement a range of measures, including people, process, and technology
        controls to protect the security of your personal information. Examples
        of these measures include:
      </PrivacyPolicyParagraph>
      <ul>
        <PrivacyPolicyBulletPointListObject>
          Access to personal information is controlled through access and
          identity management systems;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Team members are bound by internal confidentiality and information
          security policies that require them to keep personal information
          secure at all times;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Protecting personal information in accordance with the Office of the
          Information Commissioner’s Guide to Securing Personal Information;
          and,
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Maintaining an ongoing security program where we invest continually in
          cyber security.
        </PrivacyPolicyBulletPointListObject>
      </ul>

      <PrivacyPolicyParagraph>
        Our security controls are continually reviewed to protect your personal
        information appropriately.
      </PrivacyPolicyParagraph>
    </PrivacyPolicyOrderedListObject>
  )
}

function SharingOfPersonalInformationContent() {
  return (
    <PrivacyPolicyOrderedListObject>
      <span>4. Sharing of personal information</span>

      <PrivacyPolicyParagraph>
        We often work co-operatively within the Coles Group, and may share
        personal information with other members of the Group. We also work with
        suppliers and third parties that carry out specific functions on our
        behalf, so that we can provide you with goods and services and carry out
        the activities listed in Section 2. These third parties assist us with
        the below services, which includes, but is not limited to:
      </PrivacyPolicyParagraph>

      <ul>
        <PrivacyPolicyBulletPointListObject>
          Technology services including application development, technical
          support, and processing, storing, hosting and analysing data;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Processing payments;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Communicating our offers and promotions to you;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Product development and market research;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Store security and investigative, fraud, loss prevention, and safety
          activities;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Business advisory services, such as our lawyers, accountants, or other
          professional service providers to the extent reasonably required; and,
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Administrative services, including mailing services, printing,
          archival, and contact management services.
        </PrivacyPolicyBulletPointListObject>
      </ul>

      <PrivacyPolicyParagraph>
        Other third parties we share personal information with include:
      </PrivacyPolicyParagraph>

      <ul>
        <PrivacyPolicyBulletPointListObject>
          your representatives;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          police, courts, government agencies and lawyers, e.g. in in connection
          with regulatory and legal investigations and processes; and
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          parties involved in business transfer transactions (and prospective
          transactions).
        </PrivacyPolicyBulletPointListObject>
      </ul>

      <PrivacyPolicyParagraph>
        We use systems, customer service teams and service providers located
        within Australia and in overseas locations that include Argentina,
        Canada, EU Member States, Hong Kong, India, Israel, Japan, New Zealand,
        Philippines, Singapore, South Africa, United Kingdom, United States, and
        Vietnam. We may share personal information with third parties in these
        countries. From time to time, this list of countries may change.
      </PrivacyPolicyParagraph>
    </PrivacyPolicyOrderedListObject>
  )
}

function ColesDigitalServicesContent() {
  return (
    <PrivacyPolicyOrderedListObject>
      <span>Coles Digital Services</span>

      <PrivacyPolicyParagraph>
        Coles operates a range of websites, mobile apps, email services, online
        advertisements and social media profiles, which are collectively known
        as Coles Digital Services. We want you to be confident that your
        personal information is being used to offer you a better and more
        personalised experience across Coles.
      </PrivacyPolicyParagraph>
      <PrivacyPolicyParagraph>
        We and our suppliers (such as Facebook, Google, Oracle and Adobe) use
        various technologies, such as cookies, beacons, tags and pixels, to
        personalise and improve your customer experience as you use our Coles
        Digital Services. Cookies and similar technologies may also help us to
        detect fraudulent activity or to prevent security breaches and so we may
        collect information about your device from within the cookie.
      </PrivacyPolicyParagraph>
      <PrivacyPolicyParagraph>
        These technologies within our Coles Digital Services may be used to do
        the following:
      </PrivacyPolicyParagraph>

      <ul>
        <PrivacyPolicyBulletPointListObject>
          <strong>Improve the way our websites and mobile apps work</strong> –
          these technologies allow us to improve the way our websites and mobile
          apps work so that we can personalise your experience and allow you to
          use many of their useful features. For example, we use cookies so we
          can remember your preferences and the contents of your shopping basket
          when you return to our websites and mobile apps.
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          <strong>
            Improve the performance of our websites and mobile apps
          </strong>{' '}
          – these technologies can help us to understand how our websites and
          mobile apps are being used, for example, by telling us if you get an
          error messages as you browse.
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          <strong>
            Deliver relevant online advertising, including via social media
          </strong>{' '}
          – these technologies to help us deliver online advertising that we
          believe is most relevant to you on our websites, other organisations’
          websites, and on social media. These technologies may collect
          information about your online behaviour, such as your IP address, the
          website you arrived from and information about your purchase history
          or the content of your shopping basket. This means that you may see
          our adverts on our websites and on other organisations’ websites. You
          may also see adverts for other organisations on our websites. To help
          us deliver online advertising that is relevant to you, we may also
          combine data we collect through cookies and similar technologies on
          your devices with other data that we have collected, for example your
          use of loyalty cards and in-store purchases.
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          <strong>
            Measuring the effectiveness of our marketing communications,
            including online advertising
          </strong>{' '}
          – marketing communications and online advertising may be in the form
          of email, SMS, push notification through our app, web notifications
          through our website, display banners on external websites, and social
          media platforms. Cookies and similar technologies can tell us, for
          example, if you have seen a specific advert, and how long it has been
          since you have seen it. This information allows us to measure the
          effectiveness of our online advertising campaigns and control the
          number of times you are shown an advert. We also use cookies and
          similar technologies to measure the effectiveness of our marketing
          communications, for example by telling us if you have opened a
          marketing email that we have sent.
        </PrivacyPolicyBulletPointListObject>
      </ul>
      <PrivacyPolicyParagraph>
        You can configure your browser or device to reject and delete cookies,
        block JavaScript, disable GPS location services and anonymise your usage
        patterns. If you choose to do so, this may limit the functionality of
        some parts of Coles Digital Services for you. For example, you may not
        be able to add items to your shopping basket, proceed to checkout, or
        use our products and services that require you to sign in.
      </PrivacyPolicyParagraph>
      <PrivacyPolicyParagraph>
        You can opt out of receiving promotional email and SMS communications at
        any time by either:
      </PrivacyPolicyParagraph>
      <ul>
        <PrivacyPolicyBulletPointListObject>
          Clicking unsubscribe within the Coles email;
        </PrivacyPolicyBulletPointListObject>
        <PrivacyPolicyBulletPointListObject>
          Calling our Coles contact centre on{' '}
          <ModalLink href='tel:1800061562'>1800 061 562</ModalLink>; or,
        </PrivacyPolicyBulletPointListObject>{' '}
        <PrivacyPolicyBulletPointListObject>
          Texting “STOP” to opt out of SMS.
        </PrivacyPolicyBulletPointListObject>
      </ul>

      <PrivacyPolicyParagraph>
        There are, however, some service and transactional messages that we must
        be able to send you. These include, for example, messages relating to
        your account, forgotten passwords, transactional receipts, updates to
        our T&amp;Cs and Privacy Policy, and product recall notices.
      </PrivacyPolicyParagraph>
    </PrivacyPolicyOrderedListObject>
  )
}

function ProceduresForAccessContent() {
  return (
    <PrivacyPolicyOrderedListObject>
      <span>
        Procedures for access to or correction of your personal information
      </span>

      <PrivacyPolicyParagraph>
        If you wish to access or correct any personal information we hold about
        you, please contact us as set out in Section 8.
      </PrivacyPolicyParagraph>
      <PrivacyPolicyParagraph>
        When making an access request, please provide as much detail as you can
        about the specific information you seek, in order to help us retrieve
        it. Under the Privacy Act and other relevant laws, we are required to
        provide a written response to you if we are unable to respond to your
        request, outlining the reasons why we were unable to do so.
      </PrivacyPolicyParagraph>
      <PrivacyPolicyParagraph>
        Where you request Coles to correct information we hold about you, but
        Coles elects not to make the requested correction, you may request Coles
        to add a note to your information outlining your position.
      </PrivacyPolicyParagraph>
    </PrivacyPolicyOrderedListObject>
  )
}

function QuestionsAndComplaintsContent() {
  return (
    <PrivacyPolicyOrderedListObject>
      <span>Questions and complaints</span>

      <PrivacyPolicyParagraph>
        If you have any questions or complaints about this Policy, or our
        handling of your personal information, you can contact us as set out in
        Section 8.
      </PrivacyPolicyParagraph>
      <PrivacyPolicyParagraph>
        Once a complaint has been lodged, we will let you know who will be
        handling your matter and when you can expect a full response within 30
        days. If you are not satisfied with our response, please let us know and
        we will investigate further and respond to you.
      </PrivacyPolicyParagraph>
      <PrivacyPolicyParagraph>
        If you are still not satisfied, you can contact the Office of the
        Australian Information Commissioner, whose contact details are set out
        in Section 8.
      </PrivacyPolicyParagraph>
    </PrivacyPolicyOrderedListObject>
  )
}

function ContactDetailsContent() {
  return (
    <PrivacyPolicyOrderedListObject>
      <span>Contact Details</span>
      <ContactDetailsParagraph>
        Queries regarding privacy should be directed to Coles Customer Care at:
      </ContactDetailsParagraph>
      <ContactDetailsParagraph>
        Email (via webform):{' '}
        <ModalLink href='https://www.coles.com.au/customer-service/contact-us'>
          www.coles.com.au/customer-service/contact-us
        </ModalLink>{' '}
        <br />
        <br />
        Phone: <ModalLink href='tel:1800061562'>1800 061 562</ModalLink> <br />
        <br />
        Post: Privacy Officer, Coles Group, 800 Toorak Road, Hawthorn East VIC
        3123
      </ContactDetailsParagraph>

      <SubHeader>Office of the Australian Information Commissioner</SubHeader>
      <ContactDetailsParagraph>
        GPO Box 5218 Sydney NSW 2001 <br />
        <br />
        Telephone: <ModalLink href='tel:1300363992'>
          1300 363 992
        </ModalLink>{' '}
        <br />
        <br />
        Email:{' '}
        <ModalLink href='mailto:enquiries@oaic.gov.au'>
          enquiries@oaic.gov.au
        </ModalLink>{' '}
        <br />
        <br />
        Website:{' '}
        <ModalLink href='https://www.oaic.gov.au'>www.oaic.gov.au</ModalLink>
      </ContactDetailsParagraph>
    </PrivacyPolicyOrderedListObject>
  )
}

function PrivacyModelContent() {
  return (
    <PrivacyModelContentDiv>
      <PrivacyPolicyUnorderedList>
        <OverviewContent />
      </PrivacyPolicyUnorderedList>
      <PrivacyPolicyOrderedList>
        <WhyDoWeCollectAndHandleYourPersonalInformationContent />
        <TypesOfPersonalInformationCollectedContent />
        <HowPersonalInformationIsCollectedContent />
        <SharingOfPersonalInformationContent />
        <ColesDigitalServicesContent />
        <ProceduresForAccessContent />
        <QuestionsAndComplaintsContent />
        <ContactDetailsContent />
      </PrivacyPolicyOrderedList>
    </PrivacyModelContentDiv>
  )
}

export function PrivacyPolicyModalNew(props: GenericModalProps) {
  return (
    <GenericModal {...props}>
      <PrivacyModelContent />
    </GenericModal>
  )
}
