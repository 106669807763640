import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

export interface PasswordStrengthEstimateProps {
  strength: number
}

const PasswordStrengthSegmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`

const PasswordStrengthSegmentOne = styled.div<PasswordStrengthEstimateProps>`
  height: 2px;
  width: 32%;
  background: ${(props) => findColorPasswordStrengthSegment(props.strength)};
`

const PasswordStrengthSegmentTwo = styled.div<PasswordStrengthEstimateProps>`
  height: 2px;
  width: 32%;
  background: ${(props) => findColorPasswordStrengthSegment(props.strength)};
`

const PasswordStrengthSegmentThree = styled.div<PasswordStrengthEstimateProps>`
  height: 2px;
  width: 32%;
  background: ${(props) => findColorPasswordStrengthSegment(props.strength)}; ;
`

const PasswordStrengthLabel = styled.div`
  width: 320px;
  height: 16px;
  color: #3d3d3d;
`

const PasswordStrengthTextContainer = styled.span<PasswordStrengthEstimateProps>`
  color: ${(props) => findColorPasswordStrengthSegment(props.strength)};
`

const findColorPasswordStrengthSegment = (
  passwordStrengthIndicator: number
) => {
  switch (passwordStrengthIndicator) {
    case 0:
      return '#8D8D8D'
    case 1:
      return '#b9091d'
    case 2:
      return '#c55307'
    case 3:
      return '#07883d'
    default:
      return '#8D8D8D'
  }
}

export const PasswordStrengthEstimate = (
  props: PasswordStrengthEstimateProps
) => {
  const [strengthText, setStrengthText] = useState('')

  useEffect(() => {
    switch (props.strength) {
      case 0:
        setStrengthText('')
        break
      case 1:
        setStrengthText('Low')
        break
      case 2:
        setStrengthText('Moderate')
        break
      case 3:
        setStrengthText('Strong')
        break
    }
  }, [props.strength])

  return (
    <React.Fragment>
      <PasswordStrengthLabel>
        Strength:{' '}
        <PasswordStrengthTextContainer strength={props.strength}>
          {strengthText}
        </PasswordStrengthTextContainer>
      </PasswordStrengthLabel>
      <br />
      <PasswordStrengthSegmentContainer>
        <PasswordStrengthSegmentOne strength={props.strength} />
        <PasswordStrengthSegmentTwo
          strength={props.strength > 1 ? props.strength : 0}
        />
        <PasswordStrengthSegmentThree
          strength={props.strength > 2 ? props.strength : 0}
        />
      </PasswordStrengthSegmentContainer>
    </React.Fragment>
  )
}
